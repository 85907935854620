import React from "react"
import { colors } from "../../utils"
import "./five.css"
import "../section-three/gains3.css"
function ListItem({ header, content }) {
  return (
    <div className="d-flex flex-column mb-5" style={{ maxWidth: "400px" }}>
      <p
        className="mb-4"
        style={{
          color: "#F85515",
          fontSize: "24px",
          fontWeight: 500,
          fontFamily: "Poppins",
        }}
      >
        {header}
      </p>
      <p
        style={{ color: colors.basic, fontWeight: 500, fontFamily: "Poppins" }}
      >
        {content}
      </p>
    </div>
  )
}

ListItem = React.memo(ListItem)

function PromoItem(props) {
  return (
    <div className="in-grid-tile" key={props.title}>
      <div className="tile-five">
        <div className="single-promo rounded text-center white-bg px-3 py-5 mx-2">
          <div className="circle-icon mb-3 circle-icon-three">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="24"
              height="24"
              viewBox="0 0 24 24"
            >
              <g transform="translate(0 24) rotate(-90)">
                <path d="M0,0H24V24H0Z" fill="#F8F9FA" />
                <path
                  fill="#f85515"
                  d="M12,2A10,10,0,1,0,22,12,10,10,0,0,0,12,2ZM10,16.5v-9L16,12Z"
                />
              </g>
            </svg>
          </div>
          <h5 style={{ fontSize: "48px", color: "#0B3A52" }}>{props.title}</h5>
          <p
            style={{
              color: "#ADB5BD",
              fontSize: "24px",
              fontWeight: 500,
              marginBottom: "8px",
              fontFamily: "Poppins"
            }}
          >
            {props.description}
          </p>

          {props.additional && (
            <p
              style={{ color: "#ADB5BD", fontSize: "16px", marginBottom: "0", fontFamily: "Poppins" }}
            >
              {props.additional}
            </p>
          )}
        </div>
      </div>
    </div>
  )
}

PromoItem = React.memo(PromoItem)

export default function SectionFive() {
  return (
    <section id="section-five" className="">
      <div className="container">
        <div
          className="row align-items-center justify-content-center"
          style={{ marginTop: "80px", marginBottom: "80px" }}
        >
          <h2
            style={{
              color: colors.basic,
              fontSize: "48px",
              textAlign: "center",
              fontFamily: "Poppins"
            }}
          >
            Let the numbers speak
          </h2>
        </div>
        <div className="row">
          <div className="col-md-6">
            <div style={{ top: "80px", position: "sticky" }}>
              <ListItem
                header="Strength"
                content="Duis rhoncus dui venenatis consequat porttitor. Etiam aliquet congue consequat."
              />
              <ListItem
                header="Speed"
                content="Duis rhoncus dui venenatis consequat porttitor. Etiam aliquet congue consequat."
              />
              <ListItem
                header="Power"
                content="Duis rhoncus dui venenatis consequat porttitor. Etiam aliquet congue consequat."
              />
              <ListItem
                header="Endurance"
                content="Duis rhoncus dui venenatis consequat porttitor. Etiam aliquet congue consequat."
              />
            </div>
          </div>
          <div
            className="col-md-6"
            style={{
              display: "grid",
              gridTemplateColumns: "1fr 1fr",
              gridAutoFlow: "row",
              gap: "24px",
            }}
          >
            <PromoItem title="79%" description="Isometric Strenght" />
            <PromoItem
              title="37%"
              description="Isokinetic Speed Strenght"
              additional="(Eccentric)"
            />
            <PromoItem title="12%" description="Power Overall" />
            <PromoItem
              title="41%"
              description="Vertical Jump Height"
              additional="(Squat Jump)"
            />
            <PromoItem
              title="41%"
              description="Isokinetic Speed Strenght"
              additional="(Concentric)"
            />
            <PromoItem title="74%" description="Rate of Force Development" />
            <PromoItem title="4.8%" description="Sprint Times" />
            <PromoItem title="29%" description="Force Impulse" />
          </div>
        </div>
      </div>
    </section>
  )
}

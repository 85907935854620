import React from "react"
import Layout from "../components/layout"
import { PasswordGuardContext } from "../components/password-guard.context"
import SectionOne from "../components/section-one/one"
import SectionTwo from "../components/section-two/two"
import SectionThree from "../components/section-three/three"
import SectionFour from "../components/section-four/four"
import SectionFive from "../components/section-five/five"
import SectionTwelve from "../components/section-twelve/twelve"
import SectionSeven from "../components/section-seven/seven"
import SectionNine from "../components/section-nine/nine"
import SectionTen from "../components/section-ten/ten"
import SectionEleven from "../components/section-eleven/eleven"
import SectionSix from "../components/section-six/six"
import SectionEight from "../components/section-eight/eight"
import SEO from "../components/seo"

const IndexPage = () => {
  const { isVerified } = React.useContext(PasswordGuardContext)
  if (isVerified)
    return (
      <Layout>
        <SEO lang="en" title="Home" />
        <SectionOne />
        <SectionTwo />
        <SectionThree />
        <SectionFour />
        <SectionFive />
        <SectionSix />
        <SectionSeven />
        <SectionEight />
        <SectionNine />
        <SectionTen />
        <SectionEleven />
        <SectionTwelve />
      </Layout>
    )

  return null
}

export default IndexPage

import React, { useState } from "react"
import "./two.css"
import { graphql, useStaticQuery } from "gatsby"
import GatsbyImage from "gatsby-image"
import { colors } from "../../utils"
import classNames from "classnames"

function ListItem({ itemId, active, onClick, header, content }) {
  return (
    <div
      className={`single-feature mb-4 mt-5 ${active ? "left-border" : ""}`}
      onClick={onClick}
    >
      <div className="icon-box-wrap d-flex align-items-center mb-2">
        <div className="mr-3 icon-box">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="48.043"
            height="46.213"
            viewBox="0 0 48.043 46.213"
          >
            <g
              id="Polygon_1"
              data-name="Polygon 1"
              transform="translate(16) rotate(30)"
              fill="#f8f9fa"
            >
              <path
                d="M 25.44214248657227 31 L 11.55785179138184 31 C 10.49020195007324 31 9.495012283325195 30.42580986022949 8.960641860961914 29.50149917602539 L 2.023141860961914 17.50151062011719 C 1.487711906433105 16.57534027099609 1.487711906433105 15.42465019226074 2.023141860961914 14.49849033355713 L 8.960641860961914 2.49849009513855 C 9.495012283325195 1.574190020561218 10.49020195007324 1 11.55785179138184 1 L 25.44214248657227 1 C 26.50979232788086 1 27.50498199462891 1.574190020561218 28.03934097290039 2.49849009513855 L 34.97686004638672 14.49849033355713 C 35.51229095458984 15.42465972900391 35.51229095458984 16.57534980773926 34.97686004638672 17.50151062011719 L 28.03935241699219 29.50151062011719 C 27.50498199462891 30.42580986022949 26.50979232788086 31 25.44214248657227 31 Z"
                stroke="none"
              />
              <path
                d="M 11.55785179138184 2 C 10.84609222412109 2 10.18262100219727 2.38279914855957 9.826381683349609 2.999000549316406 L 2.888881683349609 14.99900054931641 C 2.53192138671875 15.61643981933594 2.53192138671875 16.38356018066406 2.888881683349609 17.00099945068359 L 9.826381683349609 29.00101089477539 C 10.18262100219727 29.61721038818359 10.84608268737793 30 11.55785179138184 30 L 25.44214248657227 30 C 26.15391159057617 30 26.8173713684082 29.61721038818359 27.17361068725586 29.00099945068359 L 34.11112213134766 17.00099945068359 C 34.46808242797852 16.38356018066406 34.46808242797852 15.61643981933594 34.11112213134766 14.99900054931641 L 27.17361068725586 2.998989105224609 C 26.8173713684082 2.382789611816406 26.15391159057617 2 25.44214248657227 2 L 11.55785179138184 2 M 11.55785179138184 0 L 25.44214248657227 0 C 26.87038230895996 0 28.19025230407715 0.7615203857421875 28.90508270263672 1.997989654541016 L 35.84259033203125 13.99798965454102 C 36.55867004394531 15.23661041259766 36.55867004394531 16.76338958740234 35.84259033203125 18.00201034545898 L 28.90508270263672 30.00201034545898 C 28.19025230407715 31.23847961425781 26.87038230895996 32 25.44214248657227 32 L 11.55785179138184 32 C 10.1296215057373 32 8.809741973876953 31.23847961425781 8.094911575317383 30.00201034545898 L 1.157413482666016 18.00201034545898 C 0.4413337707519531 16.76338005065918 0.4413337707519531 15.23661041259766 1.157413482666016 13.99798965454102 L 8.094911575317383 1.997989654541016 C 8.809751510620117 0.7615203857421875 10.1296215057373 0 11.55785179138184 0 Z"
                stroke="none"
                className={
                  active ? "section-two__hex__active" : "section-two__hex"
                }
              />
            </g>
          </svg>
        </div>
        <p
          style={{
            fontSize: "24px",
            lineHeight: "36px",
            fontWeight: "500",
            fontFamily: "Poppins",
          }}
          className={classNames(
            "mb-0",
            { "section-two__item-header": !active },
            { "section-two__item-header__active": active }
          )}
        >
          {header}
        </p>
      </div>
      <p
        style={{
          marginLeft: "4.5rem",
          fontFamily: "Poppins",
          fontSize: 16,
        }}
        className={classNames(
          { "section-two__item-content": !active },
          { "section-two__item-content__active": active }
        )}
      >
        {content}
      </p>
    </div>
  )
}

export default function SectionTwo() {
  const data = useStaticQuery(query)
  const [selectedItem, setSelectedItem] = useState(1)

  return (
    <section id="section-two" className="about-us ptb-100 background-shape-img">
      <div className="container">
        <div className="row align-items-center justify-content-between">
          <div className="col-md-5">
            <div className="about-content-left">
              {selectedItem === 1 && (
                <GatsbyImage
                  className="img-fluid"
                  fluid={data.first.childImageSharp.fluid}
                  alt="animation"
                />
              )}
              {selectedItem === 2 && (
                <GatsbyImage
                  className="img-fluid"
                  fluid={data.second.childImageSharp.fluid}
                  alt="animation"
                />
              )}
              {selectedItem === 3 && (
                <GatsbyImage
                  className="img-fluid"
                  fluid={data.third.childImageSharp.fluid}
                  alt="animation"
                />
              )}
              {selectedItem === 4 && (
                <GatsbyImage
                  className="img-fluid"
                  fluid={data.fourth.childImageSharp.fluid}
                  alt="animation"
                />
              )}
            </div>
          </div>
          <div className="col-md-7">
            <div className="about-content-right section-heading">
              <h2 style={{ color: colors.basic, fontSize: "48px" }}>
                <span style={{ color: colors.grey, fontSize: "48px" }}>
                  Kor
                </span>{" "}
                Performance Matrix
              </h2>
              <h3
                style={{
                  color: colors.grey,
                  fontWeight: 500,
                  fontSize: "24px",
                }}
              >
                Athletic system for growth and development.
              </h3>

              <ListItem
                itemId={1}
                onClick={() => setSelectedItem(1)}
                active={selectedItem === 1}
                header="Perform"
                content="Praesent eu dolor eu orci vehicula euismod. Vivamus sed sollicitudin libero, vel malesuada velit. Nullam et."
              />
              <ListItem
                itemId={2}
                onClick={() => setSelectedItem(2)}
                active={selectedItem === 2}
                header="Treat"
                content="Phasellus finibus enim nulla, quis ornare odio facilisis eu. Suspendisse ornare ante sit amet arcu semper."
              />
              <ListItem
                itemId={3}
                onClick={() => setSelectedItem(3)}
                active={selectedItem === 3}
                header="Repair"
                content="Sed a magna semper, porta purus eu, ullamcorper ligula. Nam sit amet consectetur sapien. Etiam dui ipsum."
              />
              <ListItem
                itemId={4}
                onClick={() => setSelectedItem(4)}
                active={selectedItem === 4}
                header="Recover"
                content="Mauris imperdiet orci dapibus, commodo libero nec, interdum tortor. Morbi in nibh faucibus, iaculis lorem."
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  )
}

export const query = graphql`
  query TwoQuery {
    first: file(relativePath: { eq: "section-two-equipod.png" }) {
      childImageSharp {
        fluid(maxWidth: 400) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    second: file(relativePath: { eq: "Pam.png" }) {
      childImageSharp {
        fluid(maxWidth: 400) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    third: file(relativePath: { eq: "Ricky.png" }) {
      childImageSharp {
        fluid(maxWidth: 400) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }

    fourth: file(relativePath: { eq: "Charlie.png" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
  }
`

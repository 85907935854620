import React from "react"
import "./ten.css"
import { colors } from "../../utils"
import GatsbyImage from "gatsby-image"
import { graphql, useStaticQuery, Link } from "gatsby"
import { useEmblaCarousel } from "embla-carousel/react"

export default function SectionTen() {
  const data = useStaticQuery(query)
  const [EmblaCarouselReact, embla] = useEmblaCarousel({
    startIndex: 0,
    slidesToScroll: 3,
  })

  return (
    <React.Fragment>
      <section style={{ marginBottom: 50 }}>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-8">
              <div className="smt-4 text-center">
                <h1
                  style={{
                    color: colors.basic,
                    fontSize: 48,
                  }}
                >
                  Meet our ambassadors
                </h1>
                <p className="content-text">
                  Integer ac interdum lacus. Nunc porta semper lacus a varius.
                  Pellentesque habitant morbi tristique senectus et netus et
                  malesuada fames ac turpis egestas.
                </p>
                <div className="download-btn mt-5" style={{ marginBottom: 80 }}>
                  <a
                    href="/ambassadors"
                    target="_blank"
                    rel="noopener norefferer"
                    className="btn app-store-btn"
                  >
                    See all ambasadors
                    <span style={{ marginLeft: 10 }}>
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="32"
                        height="32"
                        viewBox="0 0 32 32"
                        preserveAspectRatio="xMidYMid meet"
                      >
                        <g
                          transform="translate(1.333 2.9)"
                          fill="#0b3a52"
                          stroke="none"
                        >
                          <path d="M20.013,11.766H5.333a1.333,1.333,0,1,0,0,2.667h14.68V16.82a.66.66,0,0,0,1.133.467l3.707-3.72a.685.685,0,0,0,0-.947L21.147,8.9a.663.663,0,0,0-1.133.467Z" />
                        </g>
                      </svg>
                    </span>
                  </a>
                </div>
              </div>
            </div>
            <>
              <EmblaCarouselReact>
                <div className="carousel__container">
                  <div className="main-placeholder">
                    <div className="mb-lg-0">
                      <div className="text-container">
                        <GatsbyImage
                          style={{ width: 364, height: 364 }}
                          className="img-fluid"
                          fluid={data.third.childImageSharp.fluid}
                          alt="animation"
                        />
                        <div>
                          <h5 className="title-ten">Ricky Lightfoot</h5>
                          <p className="content-ten mb-0">
                            World Trail Running Champion, Mountain Runner -
                            Great Britain & England
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="main-placeholder">
                    <div className="mb-lg-0">
                      <div className="text-container">
                        <GatsbyImage
                          style={{ width: 364, height: 364 }}
                          className="img-fluid"
                          fluid={data.second.childImageSharp.fluid}
                          alt="animation"
                        />
                        <div>
                          <h5 className="title-ten">Pam Thorburn</h5>
                          <p className="content-ten mb-0">
                            British Champion Alpine & Ski Cross, British World
                            Cup Team Alpine & Ski Cross
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="main-placeholder">
                    <div className="mb-lg-0">
                      <div className="text-container">
                        <GatsbyImage
                          style={{ width: 364, height: 364 }}
                          className="img-fluid"
                          fluid={data.first.childImageSharp.fluid}
                          alt="animation"
                        />
                        <div>
                          <h5 className="title-ten">Charlie Guest</h5>
                          <p className="content-ten mb-0">
                            Olympic Alpine Skier - Slalom & Parallel Slalom, 1st
                            British Woman To Ever Win A European Cup
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="main-placeholder">
                    <div className="mb-lg-0">
                      <div className="text-container">
                        <GatsbyImage
                          style={{ width: 364, height: 364 }}
                          className="img-fluid"
                          fluid={data.second.childImageSharp.fluid}
                          alt="animation"
                        />
                        <div>
                          <h5 className="title-ten">Pam Thorburn</h5>
                          <p className="content-ten mb-0">
                            British Champion Alpine & Ski Cross, British World
                            Cup Team Alpine & Ski Cross
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="main-placeholder">
                    <div className="mb-lg-0">
                      <div className="text-container">
                        <GatsbyImage
                          style={{ width: 364, height: 364 }}
                          className="img-fluid"
                          fluid={data.first.childImageSharp.fluid}
                          alt="animation"
                        />
                        <div>
                          <h5 className="title-ten">Charlie Guest</h5>
                          <p className="content-ten mb-0">
                            Olympic Alpine Skier - Slalom & Parallel Slalom, 1st
                            British Woman To Ever Win A European Cup
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </EmblaCarouselReact>
            </>
            <div className="arrow-container">
              <button
                className="carousel__button"
                style={{ marginRight: "8px" }}
                onClick={() => {
                  embla.scrollPrev()
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="32"
                  height="32"
                  viewBox="0 0 32 32"
                  style={{ transform: "rotate(180deg)" }}
                >
                  <g transform="translate(32 32) rotate(180)">
                    <path fill="none" d="M0,0H32V32H0Z" />
                    <path
                      fill="#0b3a52"
                      d="M16.808,6.807a1.328,1.328,0,0,0-1.88,0l-6.12,6.12a1.328,1.328,0,0,0,0,1.88l6.12,6.12a1.329,1.329,0,1,0,1.88-1.88l-5.173-5.187,5.173-5.173A1.34,1.34,0,0,0,16.808,6.807Z"
                      transform="translate(2.806 2.139)"
                    />
                  </g>
                </svg>
              </button>
              <button
                onClick={() => {
                  embla.scrollNext()
                }}
                className="carousel__button"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="32"
                  height="32"
                  viewBox="0 0 32 32"
                >
                  <g transform="translate(32 32) rotate(180)">
                    <path fill="none" d="M0,0H32V32H0Z" />
                    <path
                      fill="#0b3a52"
                      d="M16.808,6.807a1.328,1.328,0,0,0-1.88,0l-6.12,6.12a1.328,1.328,0,0,0,0,1.88l6.12,6.12a1.329,1.329,0,1,0,1.88-1.88l-5.173-5.187,5.173-5.173A1.34,1.34,0,0,0,16.808,6.807Z"
                      transform="translate(2.806 2.139)"
                    />
                  </g>
                </svg>
              </button>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  )
}

export const query = graphql`
  query TenQuery {
    first: file(relativePath: { eq: "Charlie.png" }) {
      childImageSharp {
        fluid(maxWidth: 400) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    second: file(relativePath: { eq: "Pam.png" }) {
      childImageSharp {
        fluid(maxWidth: 400) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    third: file(relativePath: { eq: "Ricky.png" }) {
      childImageSharp {
        fluid(maxWidth: 400) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
  }
`

import React, { useState } from "react"
import "./twelve.css"
import "./twelve2.css"
import { navigate } from "gatsby"

function encode(data) {
  return Object.keys(data)
    .map(key => encodeURIComponent(key) + "=" + encodeURIComponent(data[key]))
    .join("&")
}

export default function SectionTwelve() {
  const [state, setState] = useState({})

  const handleChange = e => {
    setState({ ...state, [e.target.name]: e.target.value })
  }

  const handleSubmit = e => {
    e.preventDefault()
    const form = e.target
    fetch("/thanks", {
      method: "POST",
      headers: { "Content-Type": "application/x-www-form-urlencoded" },
      body: encode({
        "form-name": form.getAttribute("name"),
        ...state,
      }),
    })
      .then(() => navigate(form.getAttribute("action")))
      .catch(error => alert(error))
  }
  return (
    <React.Fragment>
      <section className="promo-section ptb-100 section-twelve">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-7 col-md-8">
              <div className="section-heading text-center mb-5 header">
                <h1 className="twelve-title">Stay up to date</h1>
                <p className="lead twelve-under-title">
                  Duis vestibulum elit vel neque pharetra vulputate. Quisque
                  scelerisque nisi urna. Duis rutrum non risus in imperdiet.
                </p>
              </div>
              <div className="form-container">
                <form
                  data-netlify="true"
                  netlify-honeypot="bot-field"
                  name="contact"
                  className="subscribe-form"
                  method="POST"
                  action="/thanks"
                  onSubmit={handleSubmit}
                >
                  <div className="d-flex align-items-center">
                    <input
                      type="text"
                      className="form-control input subscribe-form-field"
                      id="email"
                      name="email"
                      placeholder="Enter your e-mail address."
                      autoComplete="email"
                      onChange={handleChange}
                    />
                    <input type="hidden" name="bot-field" />
                    <input type="hidden" name="form-name" value="contact" />
                    <button
                      type="submit"
                      className="button btn solid-btn subscribe-form-button"
                      id="submit"
                    >
                      <span className="subscribe-icon-1"></span>
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  )
}

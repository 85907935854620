import { useEmblaCarousel } from "embla-carousel/react"
import { graphql, useStaticQuery } from "gatsby"
import GatsbyImage from "gatsby-image"
import React from "react"
import "./six.css"

export default function SectionSix() {
  const data = useStaticQuery(query)

  const [ImageCarousel, imageEmbla] = useEmblaCarousel({
    loop: true,
  })

  const [TextCarousel, textEmbla] = useEmblaCarousel({
    loop: true,
  })

  return (
    <section
      className="about-us pt-100"
      style={{ position: "relative", overflow: "hidden" }}
    >
      <div className="background-color"></div>
      <div className="container">
        <div className="row">
          <div className="col-md-7">
            <TextCarousel>
              <div className="carousel__container">
                <div className="carousel__item">
                  <div
                    style={{ marginBottom: 160 }}
                    className="pt-5 mt-5 text-container-six"
                  >
                    <p className="testimonial__content">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="107.6"
                        height="89.6"
                        viewBox="0 0 107.6 89.6"
                        style={{
                          top: "50px",
                          position: "absolute",
                        }}
                      >
                        <path
                          id="Path_44"
                          data-name="Path 44"
                          d="M57.2-38.8a139.433,139.433,0,0,1,5.6,40q0,24-11.4,36.8T18,50.8V31.6q10,0,14.8-5.8T37.6,8.4V0H16V-38.8Zm60.4,0q6,22,6,40,0,24-11.6,36.8T78.4,50.8V31.6q10.4,0,15-5.6T98,8.4V0H76.4V-38.8Z"
                          transform="translate(-16 38.8)"
                          fill="#0b3a52"
                          opacity="0.1"
                        />
                      </svg>
                      I am so grateful to have NuroKor in my home over this
                      period. I have been using it before and after training, on
                      my knee especially. On rest days I will sometimes sit with
                      it for hours. I believe it has had a huge impact on my
                      recovery!
                    </p>
                    <p
                      style={{ color: "#3dadad", fontFamily: "Poppins" }}
                      className="mt-3 testimonial__persona"
                    >
                      Ellie Dickinson
                    </p>
                    <p
                      className="mt-3 pr-5 testimonial__persona__details"
                    >
                      GB Road & Track Cyclist, European Champion
                      <br />
                      World Championship Medal Winner
                    </p>
                  </div>
                </div>
                <div className="carousel__item">
                  <div
                    style={{ marginBottom: 160 }}
                    className="pt-5 mt-5 text-container-six"
                  >
                    <p className="testimonial__content">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="107.6"
                        height="89.6"
                        viewBox="0 0 107.6 89.6"
                        style={{
                          top: "50px",
                          position: "absolute",
                        }}
                      >
                        <path
                          id="Path_44"
                          data-name="Path 44"
                          d="M57.2-38.8a139.433,139.433,0,0,1,5.6,40q0,24-11.4,36.8T18,50.8V31.6q10,0,14.8-5.8T37.6,8.4V0H16V-38.8Zm60.4,0q6,22,6,40,0,24-11.6,36.8T78.4,50.8V31.6q10.4,0,15-5.6T98,8.4V0H76.4V-38.8Z"
                          transform="translate(-16 38.8)"
                          fill="#0b3a52"
                          opacity="0.1"
                        />
                      </svg>
                      Lorem ipsum dolor sit amet enim. Etiam ullamcorper.
                      Suspendisse a pellentesque dui, non felis. Maecenas
                      malesuada elit lectus felis, malesuada ultricies.
                      Curabitur et ligula. Ut molestie a, ultricies porta urna.
                      Vestibulum commodo volutpat a, convallis ac, laoreet enim.
                      Phasellus fermentum in, dolor.
                    </p>
                    <p
                      style={{ color: "#3dadad", fontFamily: "Poppins" }}
                      className="mt-3 testimonial__persona"
                    >
                      JJ Jadanowski
                    </p>
                    <p
                      className="mt-3 pr-5 testimonial__persona__details"
                    >
                      Project Manager / Product Owner
                      <br />
                      Gorrion Software House
                    </p>
                  </div>
                </div>
              </div>
            </TextCarousel>
            <div className="buttons-row">
              <button
                className="carousel__button"
                style={{ marginRight: "8px" }}
                onClick={() => {
                  imageEmbla.scrollPrev()
                  textEmbla.scrollPrev()
                }}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="32"
                  height="32"
                  viewBox="0 0 32 32"
                  style={{ transform: "rotate(180deg)" }}
                >
                  <g transform="translate(32 32) rotate(180)">
                    <path fill="none" d="M0,0H32V32H0Z" />
                    <path
                      fill="#3dadad"
                      d="M16.808,6.807a1.328,1.328,0,0,0-1.88,0l-6.12,6.12a1.328,1.328,0,0,0,0,1.88l6.12,6.12a1.329,1.329,0,1,0,1.88-1.88l-5.173-5.187,5.173-5.173A1.34,1.34,0,0,0,16.808,6.807Z"
                      transform="translate(2.806 2.139)"
                    />
                  </g>
                </svg>
              </button>
              <button
                onClick={() => {
                  imageEmbla.scrollNext()
                  textEmbla.scrollNext()
                }}
                className="carousel__button"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="32"
                  height="32"
                  viewBox="0 0 32 32"
                >
                  <g transform="translate(32 32) rotate(180)">
                    <path fill="none" d="M0,0H32V32H0Z" />
                    <path
                      fill="#3dadad"
                      d="M16.808,6.807a1.328,1.328,0,0,0-1.88,0l-6.12,6.12a1.328,1.328,0,0,0,0,1.88l6.12,6.12a1.329,1.329,0,1,0,1.88-1.88l-5.173-5.187,5.173-5.173A1.34,1.34,0,0,0,16.808,6.807Z"
                      transform="translate(2.806 2.139)"
                    />
                  </g>
                </svg>
              </button>
            </div>
          </div>
          <div className="col-md-5" style={{ top: "-50px" }}>
            <>
              <ImageCarousel>
                <div className="carousel__container">
                  <div className="carousel__item">
                    <GatsbyImage
                      className="img-fluid"
                      fluid={data.file.childImageSharp.fluid}
                      alt="animation"
                    />
                  </div>
                  <div className="carousel__item">
                    <GatsbyImage
                      className="img-fluid"
                      fluid={data.ben.childImageSharp.fluid}
                      alt="animation"
                    />
                  </div>
                </div>
              </ImageCarousel>
            </>
          </div>
        </div>
      </div>
    </section>
  )
}

export const query = graphql`
  query SixQuery {
    file(relativePath: { eq: "pani.png" }) {
      childImageSharp {
        fluid(maxWidth: 400) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    ben: file(relativePath: { eq: "Ben.png" }) {
      childImageSharp {
        fluid(maxWidth: 400) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
  }
`

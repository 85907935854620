import React from "react"
import "./eleven.css"
import { colors } from "../../utils"

export default function SectionEleven() {
  return (
    <React.Fragment>
      <section style={{ marginBottom: 50, marginTop: 128 }}>
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-8">
              <div className="smt-4 text-center">
                <h1 style={{ color: colors.basic, fontSize: 48 }}>
                  Powr
                  <span style={{ color: colors.grey, fontSize: 48 }}>
                    Kor
                  </span>{" "}
                </h1>
                <h1 style={{ color: colors.basic, fontSize: 48 }}>Performance Matrix</h1>
                <p className="content-text-ele">
                  Mauris imperdiet orci dapibus, commodo libero nec, interdum
                  tortor. Morbi in nibh faucibus, iaculis lorem vitae, cursus
                  velit. Etiam non blandit ex. Mauris in fringilla velit.
                </p>
              </div>
            </div>
            <div className="row">
              <div className="main-placeholder-ele">
                <div className="mb-lg-0">
                  <div className="text-container-ele">
                    <div>
                      <img style={{ width: 364, height: 364, backgroundColor: "#F8F9FA" }}></img>
                    </div>
                    <div>
                      <h5 className="title-ele">Quad Power Sleeve</h5>
                      <p className="content-ele mb-0">
                        Integer ac interdum lacus. Nunc porta semper lacus a
                        varius. Pellentesque.
                      </p>
                      <div className="row custom-row">
                        <div className=" circle-icon icon-placeholder">
                          <span className="icon-1-ele"></span>
                        </div>
                        <div className=" circle-icon icon-placeholder">
                          <span className="icon-2-ele"></span>
                        </div>
                        <div className=" circle-icon icon-placeholder">
                          <span className="icon-3-ele"></span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="main-placeholder-ele">
                <div className="mb-lg-0">
                  <div className="text-container-ele">
                    <div>
                      <img style={{ width: 364, height: 364, backgroundColor: "#F8F9FA" }}></img>
                    </div>
                    <div>
                      <h5 className="title-ele">Power Short</h5>
                      <p className="content-ele mb-0">
                        Aliquam in bibendum mauris. Sed vitae erat vel velit
                        blandit pharetra vitae nec ante.
                      </p>
                      <div className="row custom-row">
                        <div className=" circle-icon icon-placeholder">
                          <span className="icon-1-ele"></span>
                        </div>
                        <div className=" circle-icon icon-placeholder">
                          <span className="icon-4-ele"></span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="main-placeholder-ele">
                <div className="mb-lg-0">
                  <div className="text-container-ele">
                    <div>
                      <img style={{ width: 364, height: 364, backgroundColor: "#F8F9FA" }}></img>
                    </div>
                    <div>
                      <h5 className="title-ele">Calf Power Sleeve</h5>
                      <p className="content-ele mb-0">
                        Phasellus dignissim, tellus in pellentesque mollis,
                        mauris orci dignissim nisl, id gravida.
                      </p>
                      <div className="row custom-row">
                        <div className=" circle-icon icon-placeholder">
                          <span className="icon-5-ele"></span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="main-placeholder-ele">
                <div className="mb-lg-0">
                  <div className="text-container-ele">
                    <div>
                      <img style={{ width: 364, height: 364, backgroundColor: "#F8F9FA" }}></img>
                    </div>
                    <div>
                      <h5 className="title-ele">Lacus sed aliquam</h5>
                      <p className="content-ele mb-0">
                        Donec dapibus mauris id odio ornare tempus. Duis sit
                        amet accumsan justo.
                      </p>
                      <div className="row custom-row">
                        <div className=" circle-icon icon-placeholder">
                          <span className="icon-6-ele"></span>
                        </div>
                        <div className=" circle-icon icon-placeholder">
                          <span className="icon-3-ele"></span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="main-placeholder-ele">
                <div className="mb-lg-0">
                  <div className="text-container-ele">
                    <div>
                      <img style={{ width: 364, height: 364, backgroundColor: "#F8F9FA" }}></img>
                    </div>
                    <div>
                      <h5 className="title-ele">Nullam adipiscing enim</h5>
                      <p className="content-ele mb-0">
                        Cras eu elit congue, placerat dui ut, tincidunt nisl.
                        Nulla leo elit, pharetra bibendum.
                      </p>
                      <div className="row custom-row">
                        <div className=" circle-icon icon-placeholder">
                          <span className="icon-7-ele"></span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="main-placeholder-ele">
                <div className="mb-lg-0">
                  <div className="text-container-ele">
                    <div>
                      <img style={{ width: 364, height: 364, backgroundColor: "#F8F9FA" }}></img>
                    </div>
                    <div>
                      <h5 className="title-ele">Purus etiam</h5>
                      <p className="content-ele mb-0">
                        Aliquam in bibendum mauris. Sed vitae erat vel velit
                        blandit pharetra vitae nec ante.
                      </p>
                      <div className="row custom-row">
                        <div className=" circle-icon icon-placeholder">
                          <span className="icon-5-ele"></span>
                        </div>
                        <div className=" circle-icon icon-placeholder">
                          <span className="icon-8-ele"></span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  )
}

import React from "react"
// import "./gains.css"
// import "./gains2.css"
import "./gains3.css"

export default function Gains() {
  return (
    <React.Fragment>
      <section className="promo-section ptb-100">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-lg-1 col-md-1">
              <span className="hidden-el">s</span>
            </div>
            <div className="col-lg-6 col-md-6">
              <div className="section-heading text-center mb-5 header">
                <h1 className="title">Dial-in</h1>
                <h1 className="title">performance gains</h1>
                <p className="under-title-three">
                  Improve strength speed and power
                </p>
              </div>
            </div>
            <div className="col-lg-1 col-md-1">
              <span className="icon-hex-1"></span>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-md-5 col-lg-5 tile">
              <div className="single-promo text-center white-bg p-5 h-100 tile-content">
                <div className="circle-icon mb-5 icon-background">
                  <span className="icon-1-three"></span>
                </div>
                <h5 className="tile-title-three">Porta tellus massa</h5>
                <p className="tile-under-title-three">
                  Praesent eu dolor eu orci vehicula euismod. Vivamus sed
                  sollicitudin libero, vel velit.
                </p>
              </div>
            </div>
            <div className="col-md-5 col-lg-5 tile">
              <div className="single-promo text-center white-bg p-5 h-100 tile-content">
                <div className=" circle-icon mb-5 icon-background">
                  <span className="icon-2-three"></span>
                </div>
                <h5 className="tile-title-three">Tristique imperdiet</h5>
                <p className="tile-under-title-three">
                  Duis porta, ligula rhoncus euismod pretium, nisi tellus
                  eleifend odio, luctus viverra sem.
                </p>
              </div>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="col-md-5 col-lg-5 tile">
              <div className="single-promo text-center white-bg p-5 h-100 tile-content">
                <div className=" circle-icon mb-5 icon-background">
                  <span className="icon-3-three"></span>
                </div>
                <h5 className="tile-title-three">Orci non libero</h5>
                <p className="tile-under-title-three">
                  Nunc scelerisque tincidunt elit. Vestibulum non mi ipsum. Cras
                  pretium suscipit tellus sit.
                </p>
              </div>
            </div>
            <div className="col-md-5 col-lg-5 tile">
              <div className="single-promo text-center white-bg p-5 h-100 tile-content">
                <div className=" circle-icon mb-5 icon-background">
                  <span className="icon-4-three"></span>
                </div>
                <h5 className="tile-title-three">Nunc lorem nulla</h5>
                <p className="tile-under-title-three">
                  Proin ac quam et lectus vestibulum blandit. Nunc maximus nibh
                  at placerat tincidunt.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  )
}

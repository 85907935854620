import React, { useState } from "react"
import "./seven.css"

export default function SectionSeven() {
  const [navigation, setNavigation] = useState(true)
  return (
    <React.Fragment>
      <section className="promo-section ptb-100 content">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-md-1">
              <span className="icon-hex-2"></span>
            </div>
            <div className="col-md-8">
              <div className="section-heading text-center mb-5 header-seven">
                <h1 className="title">Whole body recovery</h1>
                <p className="lead under-title">
                  Integer ac interdum lacus. Nunc porta semper lacus a varius.
                  Pellentesque habitant morbi tristique senectus et netus et
                  malesuada fames ac turpis egestas.
                </p>
              </div>
            </div>
            <div className="col-md-1">
              <span className="icon-hex-3"></span>
            </div>
          </div>
          <div className="row justify-content-center">
            {navigation && (
              <>
                <div
                  className="col-sm-1 col-md-5 col-lg-5 nav-container-active"
                  onClick={() => {
                    setNavigation(true)
                  }}
                >
                  <p className="nav-element-active">Speed up recovery</p>
                </div>
                <div
                  className="col-sm-1 col-md-5 col-lg-5 nav-container-inactive"
                  onClick={() => {
                    setNavigation(false)
                  }}
                >
                  <p className="nav-element-inactive">Treat and Repair</p>
                </div>
              </>
            )}
            {!navigation && (
              <>
                <div
                  className="col-sm-1 col-md-5 col-lg-5 nav-container-inactive"
                  onClick={() => {
                    setNavigation(true)
                  }}
                >
                  <p className="nav-element-inactive">Speed up recovery</p>
                </div>
                <div
                  className="col-sm-1 col-md-5 col-lg-5 nav-container-active"
                  onClick={() => {
                    setNavigation(false)
                  }}
                >
                  <p className="nav-element-active">Treat and Repair</p>
                </div>
              </>
            )}
          </div>
        </div>
        <div className="background">
          <div className="container">
            {navigation && (
              <>
                <div className="row justify-content-center tile-container">
                  <div className="col-md-5 col-lg-5 tile">
                    <div className="single-promo text-center white-bg p-5 h-100 tile-content">
                      <div className="circle-icon mb-5 icon-background">
                        <span className="icon-1"></span>
                      </div>
                      <h5 className="tile-title">Porta tellus massa</h5>
                      <p className="tile-under-title">
                        Praesent eu dolor eu orci vehicula euismod. Vivamus sed
                        sollicitudin libero, vel velit.
                      </p>
                    </div>
                  </div>
                  <div className="col-md-5 col-lg-5 tile">
                    <div className="single-promo text-center white-bg p-5 h-100 tile-content">
                      <div className=" circle-icon mb-5 icon-background">
                        <span className="icon-2"></span>
                      </div>
                      <h5 className="tile-title">Tristique imperdiet</h5>
                      <p className="tile-under-title">
                        Duis porta, ligula rhoncus euismod pretium, nisi tellus
                        eleifend odio, luctus viverra sem.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="row justify-content-center">
                  <div className="col-md-5 col-lg-5 tile">
                    <div className="single-promo text-center white-bg p-5 h-100 tile-content">
                      <div className=" circle-icon mb-5 icon-background">
                        <span className="icon-3"></span>
                      </div>
                      <h5 className="tile-title">Orci non libero</h5>
                      <p className="tile-under-title">
                        Nunc scelerisque tincidunt elit. Vestibulum non mi
                        ipsum. Cras pretium suscipit tellus sit.
                      </p>
                    </div>
                  </div>
                  <div className="col-md-5 col-lg-5 tile">
                    <div className="single-promo text-center white-bg p-5 h-100 tile-content">
                      <div className=" circle-icon mb-5 icon-background">
                        <span className="icon-4"></span>
                      </div>
                      <h5 className="tile-title">Nunc lorem nulla</h5>
                      <p className="tile-under-title">
                        Proin ac quam et lectus vestibulum blandit. Nunc maximus
                        nibh at placerat tincidunt.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="row justify-content-center">
                  <div className="col-md-5 col-lg-5 tile">
                    <div className="single-promo text-center white-bg p-5 h-100 tile-content">
                      <div className=" circle-icon mb-5 icon-background">
                        <span className="icon-5"></span>
                      </div>
                      <h5 className="tile-title">Orci non libero</h5>
                      <p className="tile-under-title">
                        Nunc scelerisque tincidunt elit. Vestibulum non mi
                        ipsum. Cras pretium suscipit tellus sit.
                      </p>
                    </div>
                  </div>
                  <div className="col-md-5 col-lg-5 empty-tile"></div>
                </div>
              </>
            )}

            {!navigation && (
              <>
                <div className="row justify-content-center tile-container">
                  <div className="col-md-5 col-lg-5 tile">
                    <div className="single-promo text-center white-bg p-5 h-100 tile-content">
                      <div className="circle-icon mb-5 icon-background">
                        <span className="icon-1"></span>
                      </div>
                      <h5 className="tile-title">Porta tellus massa2</h5>
                      <p className="tile-under-title">
                        Praesent eu dolor eu orci vehicula euismod. Vivamus sed
                        sollicitudin libero, vel velit.
                      </p>
                    </div>
                  </div>
                  <div className="col-md-5 col-lg-5 tile">
                    <div className="single-promo text-center white-bg p-5 h-100 tile-content">
                      <div className=" circle-icon mb-5 icon-background">
                        <span className="icon-2"></span>
                      </div>
                      <h5 className="tile-title">Tristique imperdiet2</h5>
                      <p className="tile-under-title">
                        Duis porta, ligula rhoncus euismod pretium, nisi tellus
                        eleifend odio, luctus viverra sem.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="row justify-content-center">
                  <div className="col-md-5 col-lg-5 tile">
                    <div className="single-promo text-center white-bg p-5 h-100 tile-content">
                      <div className=" circle-icon mb-5 icon-background">
                        <span className="icon-3"></span>
                      </div>
                      <h5 className="tile-title">Orci non libero2</h5>
                      <p className="tile-under-title">
                        Nunc scelerisque tincidunt elit. Vestibulum non mi
                        ipsum. Cras pretium suscipit tellus sit.
                      </p>
                    </div>
                  </div>
                  <div className="col-md-5 col-lg-5 tile">
                    <div className="single-promo text-center white-bg p-5 h-100 tile-content">
                      <div className=" circle-icon mb-5 icon-background">
                        <span className="icon-4"></span>
                      </div>
                      <h5 className="tile-title">Nunc lorem nulla2</h5>
                      <p className="tile-under-title">
                        Proin ac quam et lectus vestibulum blandit. Nunc maximus
                        nibh at placerat tincidunt.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="row justify-content-center">
                  <div className="col-md-5 col-lg-5 tile">
                    <div className="single-promo text-center white-bg p-5 h-100 tile-content">
                      <div className=" circle-icon mb-5 icon-background">
                        <span className="icon-5"></span>
                      </div>
                      <h5 className="tile-title">Orci non libero2</h5>
                      <p className="tile-under-title">
                        Nunc scelerisque tincidunt elit. Vestibulum non mi
                        ipsum. Cras pretium suscipit tellus sit.
                      </p>
                    </div>
                  </div>
                  <div className="col-md-5 col-lg-5 empty-tile"></div>
                </div>
              </>
            )}
          </div>
        </div>
      </section>
    </React.Fragment>
  )
}

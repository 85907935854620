import React from "react"
import "./nine.css"
import { colors } from "../../utils"
import { graphql, useStaticQuery } from "gatsby"
import GatsbyImage from "gatsby-image"

export default function SectionNine() {
  const data = useStaticQuery(query)
  return (
    <React.Fragment>
      <section className="section-margin-special">
        <div className="background-img">
          <div className="container">
            <div className="row justify-content-center">
              <div style={{ paddingTop: 128 }} className="col-md-7">
                <GatsbyImage
                  className="img-fluid"
                  fluid={data.first.childImageSharp.fluid}
                  alt="mobile"
                />
              </div>
            </div>
          </div>
        </div>
        <div className="container d-md-block d-none">
          <div className="row justify-content-center">
            <div className="col-md-5">
              <div style={{ position: "relative", left: "70%", bottom: "60%" }}>
                <GatsbyImage
                  className="img-fluid"
                  fluid={data.second.childImageSharp.fluid}
                  alt="pod"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  )
}

export const query = graphql`
  query NineQuery {
    first: file(relativePath: { eq: "mobiles.png" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
    second: file(relativePath: { eq: "equipod.png" }) {
      childImageSharp {
        fluid(maxWidth: 800) {
          ...GatsbyImageSharpFluid_withWebp_tracedSVG
        }
      }
    }
  }
`
